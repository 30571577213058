import {useEffect} from "react";
import {useSelector} from "react-redux";
import {selectOrganisations, selectUser} from "../../../../../corelogic/usecases/authentication/authenticationSelector";
import {Organisation} from "../../../../../corelogic/models/types/simulations-display/Organisation";
import {v4 as uuid} from "uuid";

const CHATWOOT_BASE_URL = "https://app.chatwoot.com";
const CHATWOOT_WIDGET_ID = "#cw-widget-holder";

const chatwootSettings = {
    darkMode: "auto",
    hideMessageBubble: true,
    position: "right",
    locale: "fr",
    type: "standard",
    showPopoutButton: false,
};

export default function ChatwootWithProductFruitIntegration() {
    const currentPath = window.location.pathname;
    const user = useSelector(selectUser);
    const organisations = useSelector(selectOrganisations)
    const organisationsNames = organisations.map((o: Organisation) => o.name)
    const win = window as any;

    useEffect(() => {
        win.chatwootSettings = chatwootSettings;
        window.addEventListener("productfruits_button_ext_widget_init", openChatwoot);

        openProductFruitWhenChatwootClose();

        return () => {
            window.removeEventListener("productfruits_button_ext_widget_init", openChatwoot);
        };
    }, []);

    const openChatwoot = () => {
        console.log("🔓 Opening Chatwoot...");
        win.$chatwoot?.toggle("open");
    };

    const openProductFruitWhenChatwootClose = () => {
        const interval = setInterval(() => {
            const chatwootElement = document.querySelector(CHATWOOT_WIDGET_ID);
            if (!chatwootElement) return;

            console.log("✅ Chatwoot widget found, setting up observer...");
            clearInterval(interval);

            const observer = new MutationObserver(() => {
                const isChatwootClosed = chatwootElement.classList.contains("woot--hide");
                console.log("🔄 Chatwoot visibility changed:", isChatwootClosed);

                if (isChatwootClosed) {
                    console.log("📢 Chatwoot closed, showing Product Fruits");
                    win.productFruits?.api?.inAppCenter.show();
                }
            });

            observer.observe(chatwootElement, {attributes: true, attributeFilter: ["class"]});

            return () => observer.disconnect();
        }, 500);
    };

    useEffect(() => {
        if ((!user?.name || organisationsNames?.length === 0) && currentPath !== '/login') return;
        const loggedName = `${user?.name}, ${user?.email} (${organisationsNames.join(", ")})`;
        const unLoggedName = 'Utilisateur non authentifié'
        const userName = currentPath === '/login' ? unLoggedName : loggedName
        loadChatwootScript(userName);
    }, [user, organisationsNames, currentPath]);

    const loadChatwootScript = (userName: string) => {
        console.log("📝 Loading script Chatwoot...", userName);
        const script = document.createElement("script");
        script.src = `${CHATWOOT_BASE_URL}/packs/js/sdk.js`;
        script.defer = true;
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            win.chatwootSDK.run({
                websiteToken: process.env.REACT_APP_CHATWOOT_TOKEN,
                baseUrl: CHATWOOT_BASE_URL,
            });
            const uniqueId = uuid();
            console.log("📝 Setting user...", userName);
            win.$chatwoot.setUser(uniqueId || user?.email || userName, {
                email: user?.email || userName,
                name: userName,
                avatar_url: "https://gravatar.com/avatar/8ea6382a4dfefafcd7052038530cb1a3?s=400&d=robohash&r=x",
                custom_attributes: {
                    organisations: organisationsNames, // Store organisations separately
                },

            });

        };
    };

    return null;
}
